import React from "react"
import styled from "styled-components"
import { Link, useStaticQuery } from "gatsby"
import { graphql } from "gatsby"
// import BasePortableText from "@sanity/block-content-to-react"
// import clientConfig from "../../client-config"
// import serializers from "./serializers"
// import { buildImageObj } from "../lib/helpers"
// import { imageUrlFor } from "../lib/image-url"
import { Heading, Text } from "../ziibo"
// import preview from "../images/podcast-cover.jpg"
import { format } from "date-fns"
import Img from "gatsby-image"
import BasePortableText from "@sanity/block-content-to-react"
import clientConfig from "../../client-config"
import serializers from "./serializers"
// const StyledPodcastPreview = styled(Box)`
// 	--base: 450px;
// 	--multiple: 1;
// 	position: relative;
// 	width: calc(var(--base) * var(--multiple));
// 	height: var(--base);
// 	display: flex;
// 	flex-direction: column;
// 	justify-content: flex-end;
// 	margin: 0 auto 24px auto;
// 	border: 8px solid #343443;
// 	max-width: 90vw;

// 	& img {
// 		position: absolute;
// 		width: 100%;
// 		height: 100%;
// 		object-fit: cover;
// 		/* border-radius: 8px; */
// 	}

// 	& .content-container {
// 		position: relative;
// 		/* border-radius: 8px 8px 0 0; */
// 		z-index: 2;
// 		background-color: #ffffff;
// 		width: 70%;
// 		margin: 0 auto;
// 		text-align: center;
// 		padding: 12px 0 24px;
// 		border-top: 8px solid #343443;
// 		border-right: 8px solid #343443;
// 		border-left: 8px solid #343443;
// 	}
// `

const StyledPodcastPreview = styled.div`
	& .row {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		width: 80%;
		margin: 0 auto;
		& img {
			margin-right: 24px;
		}

		& .content-container {
			flex-basis: 100%;

			@media (min-width: 63.9rem) {
				flex-basis: 50%;
			}
		}
	}
`
const PodcastPreview = ({ podcast }) => {
	const data = useStaticQuery(graphql`
		query {
			file(relativePath: { eq: "podcast-cover.jpg" }) {
				childImageSharp {
					# Specify the image processing specifications right in the query.
					# Makes it trivial to update as your page's design changes.
					fluid(maxWidth: 150) {
						...GatsbyImageSharpFluid
					}
				}
			}
		}
	`)
	return (
		<>
			<StyledPodcastPreview key={podcast.title} elevation={3}>
				<Link to={`/podcast/${podcast.slug.current}`}>
					<div className="row">
						<Img
							fluid={data.file.childImageSharp.fluid}
							alt={podcast.title}
							style={{ width: "150px", height: "150px", marginRight: "2.4rem" }}
						/>
						<div className="content-container">
							<Heading fontSize={400} color="primary" shade="dark" mt={1.2}>
								{podcast.title}
							</Heading>
							<div style={{ display: "flex", alignItems: "center", marginBottom: "1.2rem" }}>
								{podcast.publishedAt && (
									<>
										<Text fontSize={200}>{format(new Date(podcast.publishedAt), "MMMM do, yyyy")}</Text>
									</>
								)}
							</div>
							<BasePortableText blocks={podcast._rawExcerpt} serializers={serializers} {...clientConfig.sanity} />
						</div>
					</div>
				</Link>
				<hr style={{ margin: "48px auto" }} />
			</StyledPodcastPreview>
		</>
	)
}

export { PodcastPreview }
