import * as React from "react"
import { graphql } from "gatsby"
import { mapEdgesToNodes, filterOutDocsWithoutSlugs, filterOutDocsPublishedInTheFuture } from "../lib/helpers"

import Layout from "../components/layout"
import { PodcastPreview } from "../components/podcast-preview"

import { Heading } from "../ziibo"
import SEO from "../components/seo"

const PodcastPage = ({ data }) => {
	const podcastNodes = (data || {}).podcasts
		? mapEdgesToNodes(data.podcasts).filter(filterOutDocsWithoutSlugs).filter(filterOutDocsPublishedInTheFuture)
		: []

	return (
		<Layout>
			<SEO title="Podcasts" />
			<main style={{ margin: "24px auto", width: "80%" }}>
				<Heading mb={2.4}>All Podcast Episodes</Heading>
				<div style={{ display: "flex", justifyContent: "space-around", flexWrap: "wrap" }}>
					{podcastNodes.map((node) => {
						return <PodcastPreview podcast={node} />
					})}
				</div>
			</main>
		</Layout>
	)
}

export default PodcastPage

export const query = graphql`
	# fragment SanityImage on SanityMainImage {
	# 	crop {
	# 		_key
	# 		_type
	# 		top
	# 		bottom
	# 		left
	# 		right
	# 	}
	# 	hotspot {
	# 		_key
	# 		_type
	# 		x
	# 		y
	# 		height
	# 		width
	# 	}
	# 	asset {
	# 		_id
	# 	}
	# }

	query PodcastPageQuery {
		podcasts: allSanityPodcast(
			sort: { fields: [publishedAt], order: DESC }
			filter: { slug: { current: { ne: null } }, publishedAt: { ne: null } }
		) {
			edges {
				node {
					id
					publishedAt
					# mainImage {
					# 	...SanityImage
					# 	alt
					# }
					title
					_rawExcerpt
					slug {
						current
					}
					# authors {
					# 	_key
					# 	author {
					# 		name
					# 	}
					# }
				}
			}
		}
	}
`
